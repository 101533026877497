// HomeComponent.js
import React from 'react';
import './HomeComponent.css';
import FormComponent from '../FormComponent/FormComponent';
import HomeImageDesktop from '../../images/home_image.webp';
import HomeImageMobile from '../../images/Home_Mobile.webp';

const HomeComponent = () => {
  return (
    <div className="home-container" id="home">
      <img className="home-image" src={window.innerWidth >= 991 ? HomeImageDesktop : HomeImageMobile} alt="Home Page img" loading="lazy" />

      <div className="home-caption">
        <h1>WORLD'S BEST</h1>
        <h1>EDUCATION SYSTEM,</h1>
        <h1>NOW IN PUNE.</h1>
      </div>

      <FormComponent id="home" />
    </div>
  );
};

export default HomeComponent;