import React, { useState, useMemo, useCallback } from "react";
import { Form, Row, Col, Dropdown, Alert } from "react-bootstrap";
import "./FormComponent.css";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const FormComponent = () => {
  const [formData, setFormData] = useState({
    child: {
      name: "",
      parentName: "",
      location: "",
      grade: "Sr.kg",
      contactNumber: "",
      email: "",
      branch: "PUNE",
    },
  });

  const validatePhoneNumber = useCallback((phoneNumber) => {
    const phoneRegex = /^[0-9]{10}$/;

    if (!phoneRegex.test(phoneNumber)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        contactNumber: "Please enter a valid 10-digit phone number.",
      }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, contactNumber: "" }));
    }
  }, []);

  const [errors, setErrors] = useState({
    name: "",
    parentName: "",
    location: "",
    grade: "",
    contactNumber: "",
    email: "",
  });

  const gradeOptions = useMemo(() => {
    const grades = [
      "Sr.kg",
      "Jr.kg",
      "1st Grade",
      "2nd Grade",
      "3rd Grade",
      "4th Grade",
      "5th Grade",
    ];
    return grades.map((grade) => ({ label: grade, value: grade }));
  }, []);

  const validateGrade = useCallback((selectedGrade) => {
    if (!selectedGrade || selectedGrade === "Select Grade") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        grade: "Please select the grade.",
      }));
      return false;
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, grade: "" }));
      return true;
    }
  }, []);

  const validateEmail = useCallback((email) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!emailRegex.test(email)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Invalid email address",
      }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, email: "" }));
    }
  }, []);

  const handleInputChange = useCallback(
    (event) => {
      const { name, value } = event.target;
      setFormData((prevData) => ({
        child: { ...prevData.child, [name]: value },
      }));
      if (name === "email") {
        validateEmail(value);
      } else if (name === "grade") {
        validateGrade();
      }
    },
    [validateEmail, validateGrade]
  );

  const showNotification = useCallback((message, type = "success") => {
    toast[type](message, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 4000,
    });
  }, []);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();

      let isValid = true;
      let updatedErrors = {
        name: "",
        parentName: "",
        location: "",
        grade: "",
        contactNumber: "",
        email: "",
      };

      if (formData.child.name === "") {
        isValid = false;
        updatedErrors.name = "Please enter the child name.";
      }

      if (formData.child.parentName === "") {
        isValid = false;
        updatedErrors.parentName = "Please enter the parent name.";
      }

      if (formData.child.location === "") {
        isValid = false;
        updatedErrors.location = "Please enter the location.";
      }

      if (formData.child.grade === "") {
        isValid = false;
        updatedErrors.grade = "Please select the grade.";
      }

      if (formData.child.contactNumber === "") {
        isValid = false;
        updatedErrors.contactNumber = "Please enter the contact number.";
      }

      if (formData.child.email === "") {
        isValid = false;
        updatedErrors.email = "Please enter the email.";
      }

      setErrors(updatedErrors);

      if (isValid) {
        emailjs
          .sendForm(
            "service_641iz9o",
            "template_lgeyiot",
            "#fis-form",
            "iuhITWKop7J9xoPlG"
          )
          .then(
            function (response) {
              console.log("Email sent successfully:");
            },
            function (err) {
              console.log("Email sending failed:", err);
            }
          );

        showNotification("Form submitted successfully", "success");

        // Clear form data
        setFormData({
          child: {
            name: "",
            parentName: "",
            location: "",
            grade: "",
            contactNumber: "",
            email: "",
          },
        });
      }
    },
    [formData, showNotification, setErrors]
  );

  const memoizedForm = useMemo(() => {
    return (
      <div className="form-container" id="home">
        <ToastContainer />

        <Form onSubmit={handleSubmit} id="fis-form">
          <Row>
            <Col md={4}>
              <Form.Group controlId="formName">
                <Form.Control
                  type="text"
                  name="name"
                  value={formData.child.name}
                  onChange={handleInputChange}
                  placeholder="Name of Child"
                  style={{
                    width: "100%",
                    height: "2rem",
                    backgroundColor: "rgba(173, 64, 128, 0.6)",
                  }}
                  required
                />
                <input name="branch" value={formData?.branch} hidden />
                {errors.name && <Alert variant="danger">{errors.name}</Alert>}
              </Form.Group>
            </Col>

            <Col md={4}>
              <Form.Group controlId="formParentName">
                <Form.Control
                  type="text"
                  name="parentName"
                  value={formData.child.parentName}
                  onChange={handleInputChange}
                  placeholder="Name of Parent"
                  style={{
                    width: "100%",
                    height: "2rem",
                    backgroundColor: "rgba(173, 64, 128, 0.6)",
                  }}
                  required
                />
                {errors.parentName && (
                  <Alert variant="danger">{errors.parentName}</Alert>
                )}
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="formLocation">
                <Form.Control
                  type="text"
                  name="location"
                  value={formData.child.location}
                  onChange={handleInputChange}
                  placeholder="Location"
                  required
                  style={{
                    width: "100%",
                    height: "2rem",
                    backgroundColor: "rgba(173, 64, 128, 0.6)",
                  }}
                />
                {errors.location && (
                  <Alert variant="danger">{errors.location}</Alert>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <input
              name="grade"
              value={formData.child.grade}
              onChange={handleInputChange}
              hidden
            />
            <input hidden name="branch" value={formData.child.branch} />
            <Col md={4}>
              <Form.Group controlId="formGrade" required>
                <Dropdown
                  onSelect={(selectedGrade) => {
                    handleInputChange({
                      target: { name: "grade", value: selectedGrade },
                    });
                    validateGrade(selectedGrade);
                  }}
                >
                  <Dropdown.Toggle
                    className="gradeDropDown"
                    variant="success"
                    required
                    id="dropdown-grade"
                    style={{
                      width: "100%",
                      height: "2rem",
                      textAlign: "start",
                      backgroundColor: "rgba(173, 64, 128, 0.6)",
                      lineHeight: "23px",
                    }}
                  >
                    {formData.child.grade || "Grade"}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {gradeOptions.map((grade, index) => (
                      <Dropdown.Item key={index} eventKey={grade.value}>
                        {grade.label}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                  {errors.grade && (
                    <Alert variant="danger">{errors.grade}</Alert>
                  )}
                </Dropdown>
              </Form.Group>
            </Col>

            <Col md={4}>
              <Form.Group controlId="formContactNumber">
                <Form.Control
                  type="number"
                  name="contactNumber"
                  value={formData.child.contactNumber}
                  placeholder="Enter contact number"
                  pattern="[0-9]*"
                  onChange={(e) => {
                    handleInputChange(e);
                    validatePhoneNumber(e.target.value);
                  }}
                  style={{
                    width: "100%",
                    height: "2rem",
                    backgroundColor: "rgba(173, 64, 128, 0.6)",
                  }}
                  required
                />
                {errors.contactNumber && (
                  <Alert variant="danger">{errors.contactNumber}</Alert>
                )}
              </Form.Group>
            </Col>

            <Col md={4}>
              <Form.Group controlId="formEmail">
                <Form.Control
                  type="email"
                  name="email"
                  value={formData.child.email}
                  onChange={handleInputChange}
                  placeholder="Enter email"
                  style={{
                    width: "100%",
                    height: "2rem",
                    backgroundColor: "rgba(173, 64, 128, 0.6)",
                  }}
                  required
                />
                {errors.email && <Alert variant="danger">{errors.email}</Alert>}
              </Form.Group>
            </Col>
          </Row>

          <div className="center-button">
            <button type="submit" className="form-btn">
              {" "}
              APPLY NOW
            </button>
          </div>
        </Form>
      </div>
    );
  }, [
    handleSubmit,
    errors.contactNumber,
    errors.email,
    errors.grade,
    errors.location,
    errors.name,
    errors.parentName,
    formData?.branch,
    formData.child.branch,
    formData.child.contactNumber,
    formData.child.email,
    formData.child.grade,
    formData.child.location,
    formData.child.name,
    formData.child.parentName,
    gradeOptions,
    handleInputChange,
    validateGrade,
    validatePhoneNumber,
  ]);

  return memoizedForm;
};

export default FormComponent;
