import React, { useState, useMemo } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import '../NavMenu/NavMenu.css';

import logo_fis from '../../images/logo-fis.png';
import Goenka_Global_logo from '../../images/Goenka_Global_logo.png';

const MobileNav = () => {
    const [isNavOpen, setIsNavOpen] = useState(false);

    const scrollToElement = (id) => {
        const element = document.getElementById(id);
        if (element) {
            const offset = document.querySelector('.navbar').offsetHeight;
            const elementPosition = element.offsetTop - offset;
            window.scrollTo({
                top: elementPosition,
                behavior: 'smooth',
            });
            closeNav();
        }
    };

    const closeNav = () => {
        setIsNavOpen(false);
    };

    const toggleNav = () => {
        setIsNavOpen(!isNavOpen);
    };

    const memoizedFisLogo = useMemo(() => (
        <img
            src={logo_fis}
            alt="Logo"
            loading="lazy"
            height="50"
            width="120px"
            className="fis_logo"
        />
    ), []);

    const memoizedGoenkaLogo = useMemo(() => (
        <img
            src={Goenka_Global_logo}
            alt="Logo"
            height="50"
            loading="lazy"
            width="120px"
            style={{ marginBottom: "10px" }}
            className="Goenka_logo"
        />
    ), []);

    return (
        <Navbar bg="light" variant="light" expand="lg" className="fixed-top navbar.nav overflowX-hidden">
            <Container fluid>
                <Navbar.Brand href="/">
                    {memoizedFisLogo}
                </Navbar.Brand>
                <div>
                    <Navbar.Brand className="ml-auto Goenka_logo" href="/">
                        {memoizedGoenkaLogo}
                    </Navbar.Brand>
                    <Navbar.Toggle
                        aria-controls="basic-navbar-nav"
                        style={{ backgroundColor: '#D24080', borderRadius: "0" }}
                        onClick={toggleNav}
                        className={isNavOpen ? 'open' : ''}
                    >
                        <span className="toggle-line"></span>
                        <span className="toggle-line"></span>
                        <span className="toggle-line"></span>
                    </Navbar.Toggle>
                </div>
                <Navbar.Collapse id="basic-navbar-nav" className={isNavOpen ? 'open' : ''}>
                    <Nav className={`mx-auto ${isNavOpen ? 'open' : ''} navbar-links`}>
                        <Nav.Link href="#home" onClick={() => scrollToElement('home')} className="nav-link-custom">
                            HOME
                        </Nav.Link>
                        <Nav.Link href="#academics" onClick={() => scrollToElement('academics')} className="nav-link-custom">
                            ACADEMICS
                        </Nav.Link>
                        <Nav.Link href="#about-us" onClick={() => scrollToElement('about')} className="nav-link-custom">
                            ABOUT US
                        </Nav.Link>
                        <Nav.Link href="#whois-fis" onClick={() => scrollToElement('whoweare')} className="nav-link-custom">
                            WHO WE ARE
                        </Nav.Link>
                        <Nav.Link href="#schoolife" onClick={() => scrollToElement('schoolife')} className="nav-link-custom">
                            SCHOOL LIFE
                        </Nav.Link>
                        <Nav.Link href="#gallery" onClick={() => scrollToElement('gallery')} className="nav-link-custom">
                            GALLERY
                        </Nav.Link>
                        <Nav.Link href="#getintouch" onClick={() => scrollToElement('home')} className="nav-link-custom">
                            GET IN TOUCH
                        </Nav.Link>

                    </Nav>
                </Navbar.Collapse>

            </Container>
        </Navbar>
    );
};

export default MobileNav;
