import React, { useMemo } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Gallery.css";
import { Animate } from "../Animate/Animate";
import Gallery1 from "../../images/Gallery1.webp";
import Gallery2 from "../../images/Gallery2.webp";
import Gallery3 from "../../images/Read_Aloud.jpg";
import Gallery4 from "../../images/Gallery4.jpg";
import Circle_Time from "../../images/Circle time.jpg";
import Parachute from "../../images/Parachute.jpg";
import Swinging from "../../images/Swinging across.jpg";
import Food_Pyramid from "../../images/EVS- Food Pyramid Group.jpg";
import Crafts_Duckling from "../../images/Crafts- Duckling.jpg";

const CustomPrevArrow = ({ onClick }) => (
  <button
    type="button"
    className="custom-arrow custom-prev-arrow carousel-control-prev"
    onClick={onClick}
  >
    <span className="visually-hidden">Previous</span>
    <i
      className="bi bi-chevron-left"
      style={{ color: "#333", fontSize: "2rem" }}
    ></i>
  </button>
);

const CustomNextArrow = ({ onClick }) => (
  <button
    type="button"
    className="custom-arrow custom-next-arrow carousel-control-next"
    onClick={onClick}
  >
    <span className="visually-hidden">Next</span>
    <i
      className="bi bi-chevron-right"
      style={{ color: "#333", fontSize: "2rem" }}
    ></i>
  </button>
);

const Gallery = () => {
  const images = useMemo(
    () => [
      Gallery1,
      Gallery2,
      Parachute,
      Swinging,
      Food_Pyramid,
      Crafts_Duckling,
      Gallery3,
      Gallery4,
      Circle_Time,
    ],
    []
  );

  const videos = useMemo(
    () => [
      "https://www.youtube.com/embed/PYgj2z4t0qg?si=R55szYV2gJTZF2Xl",
      "https://www.youtube.com/embed/FuWpkHpnHGw?si=c28sfBsoeoqVanHG",
      "https://www.youtube.com/embed/C2Li4q1Igvo?si=P4FnKhtdQVdy5Vui",
      "https://www.youtube.com/embed/2q8XKcNYcNs?si=0u_vAVzMch8Mz-R3",
      "https://www.youtube.com/embed/IT0ecELGTyQ?si=SZftK71Fxxj3DzUV",
    ],
    []
  );

  const imageSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  const videoSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  return (
    <>
      <div className="finnish-image-bg"></div>
      <div
        className="gallery-container d-flex align-items-center justify-content-center"
        id="gallery"
      >
        <div className="d-flex flex-column align-items-center mt-5">
          <h3
            style={{
              marginTop: "20px",
              marginBottom: "10px",
              color: "#D24080",
              fontfamily: "Acumin_Variable_Concept",
              fontSize: "30px",
              fontWeight: "bold",
            }}
          >
            GALLERY
          </h3>
          <div className="mb-2">
            <Animate />
          </div>
          <div className="slider-container">
            <Slider {...imageSettings}>
              {images.map((image, index) => (
                <div key={index} className="imageSlide">
                  <img
                    src={image}
                    loading="lazy"
                    style={{ marginRight: "-10px" }}
                    alt={`Gallery  ${index + 1}`}
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
      <div
        className="gallery-container d-flex align-items-center justify-content-center"
        id="gallery"
      >
        <div className="d-flex flex-column align-items-center mt-5">
          <h3
            style={{
              marginTop: "10px",
              marginBottom: "15px",
              color: "#D24080",
              fontfamily: "Acumin_Variable_Concept",
              fontSize: "30px",
              fontWeight: "bold",
            }}
          >
            VIDEO
          </h3>
          <div>
            <Animate />
          </div>
          <div className="slider-container mb-5 mt-3">
            <Slider {...videoSettings}>
              {videos.map((video, index) => (
                <div
                  key={index}
                  className="video-slide"
                  style={{ marginRight: "10px" }}
                >
                  <iframe
                    src={video}
                    loading="lazy"
                    title={`Video ${index + 1}`}
                    width="380px"
                    height="500px"
                    style={{ border: "none" }}
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
};

export default Gallery;
