import React, { useMemo } from "react";
import { Card, Container } from "react-bootstrap";
import "./SchoolLife.css";
import { Animate } from "../Animate/Animate";
import school_life from "../../images/school_life.webp";

// Memoized Image Card
const MemoizedImageCard = React.memo(() => (
  <div className="d-flex align-items-flex-end">
    <div className="overlay-school-image"></div>
    <Card className="school-image-card">
      <Card.Img
        variant="top"
        src={school_life}
        width="375"
        height="314"
        alt="school_life"
        loading="lazy"
      />
    </Card>
  </div>
));

const SchoolLife = () => {
  // Memoized JSX elements
  const memoizedElements = useMemo(
    () => (
      <div className="school-container-styles" id="schoolife">
        <Container className="d-flex justify-content-center align-items-center mt-5 mb-4">
          <div className="school-content">
            <div className="school-image">
              {/* Memoized Image Card */}
              <MemoizedImageCard />
              <div className="school-image-bg"></div>
            </div>

            <div className="school-text">
              <ul>
                <h4>SCHOOL LIFE</h4>
                <div className="school-animate">
                  <Animate />
                </div>
                <h6>
                  Beyond boundaries: a holistic approach to lifelong learning
                  excellence.
                </h6>
                <li>
                  Guided by the Finnish National Core Curriculum, our primary
                  language of instruction is English.
                </li>
                <li>Multilingual focus: Hindi, Marathi, and more.</li>
                <li>Emphasize the real-world application of knowledge.</li>
                <li>
                  Prioritize holistic development; mental and physical
                  well-being is paramount.
                </li>
                <li>
                  Embrace student-centric learning with robust support for
                  individual success.
                </li>
                <li>
                  Immerse in phenomenon-based learning for a broader,
                  interdisciplinary perspective.
                </li>
              </ul>
            </div>
          </div>
        </Container>
      </div>
    ),
    []
  );

  return memoizedElements;
};

export default SchoolLife;
