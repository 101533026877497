import React, { useMemo } from 'react';
import { Card, Container } from 'react-bootstrap';
import './About.css';
import { Animate } from '../Animate/Animate';
import about_image from '../../images/about_image.webp';

// Memoized Image Card
const MemoizedImageCard = React.memo(() => (
    <div className='d-flex align-items-end'>
        <div className="overlay-about-image"></div>
        <Card className="image-card">
            <Card.Img variant="top" src={about_image} width="375" height="315" alt="About_image" loading="lazy" />
        </Card>
    </div>
));

const About = () => {
    // Memoized JSX elements
    const memoizedElements = useMemo(
        () => (
            <div className="about-container-styles" id="about">
                <Container className="d-flex justify-content-center align-items-center mt-5 mb-4">
                    <div className="about-content">
                        <div className="about-image">
                            {/* Memoized Image Card */}
                            <MemoizedImageCard />
                            <div className="about-image-bg"></div>
                        </div>
                        <div className="about-text">
                            <h4>About Us</h4>
                            <div className="about-animate">
                                <Animate />
                            </div>
                            <p className="important-text">
                                Welcome to Finland International School (FIS) Pune, an inspirational learning community, where the focus is on both academic success and well-being.
                            </p>
                            <p className='medium-text'>
                                Our school follows the Finnish method of teaching and learning, which is internationally acknowledged for producing high-ranking student performance, while nurturing well-being for the whole learning community.
                            </p>
                            <p className="medium-text">
                                At our first exclusive boutique-style campus in Kalyani Nagar, we welcome parents to visit and to discuss with the principal by appointment.
                            </p>
                        </div>
                    </div>
                </Container>
            </div>
        ),
        []
    );

    return memoizedElements;
};

export default About;
