import React from 'react'

import './Animate.css'
export function Animate(props) {


    return (
        <>
            <div className="text-center mb-3">
                <div className="line-container">
                    <div className="line">
                        <div className="dot"></div>
                    </div>
                </div>
            </div>
        </>
    )
}
