import React, { useEffect, useState, useMemo } from 'react';
import { Card, Container, Row, Col } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import './Academics.css';
import { Animate } from '../Animate/Animate';
import Pre_Primary from '../../images/Pre-Primary.webp';
import Grade1_6 from '../../images/Grade1-6.webp';
import Grade7_10 from '../../images/Grade7-10.webp';
import IBDP from '../../images/IBDP.webp';

const Academics = () => {
  const location = useLocation();
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setAnimate(true);
  }, [location.pathname]);

  const memoizedCards = useMemo(
    () => (
      <Container className="card-container">
        <Row className="justify-content-center">
          {/* Card 1 */}
          <Col xs={12} sm={6} md={6} lg={3} className={`mb-2 card-academics ${animate ? 'slide-in' : ''}`}>
            <Card>
              <Card.Img
                variant="top"
                className="card-img-academics"
                src={Pre_Primary}
                width="132.75rem"
                height="88.5rem"
                loading="lazy"
                alt="Pre-Primary img"
              />
              <Card.Body className="card-body">
                <Card.Title className="text-center">PRE-PRIMARY</Card.Title>
              </Card.Body>
            </Card>
          </Col>

          {/* Card 2 */}
          <Col xs={12} sm={6} md={6} lg={3} className={`mb-2 card-academics ${animate ? 'slide-in' : ''}`}>
            <Card>
              <Card.Img
                variant="top"
                className="card-img-academics"
                src={Grade1_6}
                loading="lazy"
                width="132.75rem"
                height="88.5rem"
                alt="Grade 1 to 6 img"
              />
              <Card.Body className="card-body">
                <Card.Title className="text-center">GRADE 1-6</Card.Title>
              </Card.Body>
            </Card>
          </Col>

          {/* Card 3 */}
          <Col xs={12} sm={6} md={6} lg={3} className={`mb-2 card-academics ${animate ? 'slide-in' : ''}`}>
            <Card>
              <Card.Img
                variant="top"
                className="card-img-academics"
                src={Grade7_10}
                loading="lazy"
                width="132.75rem"
                height="88.5rem"
                alt="Grade 7-10 img"
              />
              <Card.Body className="card-body">
                <Card.Title className="text-center">GRADE 7-10</Card.Title>
              </Card.Body>
            </Card>
          </Col>

          {/* Card 4 */}
          <Col xs={12} sm={6} md={6} lg={3} className={`mb-2 card-academics ${animate ? 'slide-in' : ''}`}>
            <Card>
              <Card.Img
                variant="top"
                className="card-img-academics"
                src={IBDP}
                loading="lazy"
                width="132.75rem"
                height="88.5rem"
                alt="IBDP img"
              />
              <Card.Body className="card-body">
                <Card.Title className="text-center">A-LEVELS & IBDP</Card.Title>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    ),
    [animate]
  );

  return (
    <div className="academics-container-styles d-flex flex-column align-items-center" id="academics">
      <h4 className="mt-5 mb-0">
        ACADEMICS
      </h4>
      <div className="additional-content mb-3">
        <Animate />
      </div>

      {/* Rendering  memoized JSX elements */}
      {memoizedCards}
    </div>
  );
};

export default Academics;
