import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './fonts/Acumin-Variable-Concept.ttf'
import './App.css'
import Footer from './components/Footer/Footer.jsx'
import About from './components/About/About.jsx';
import SchoolLife from './components/SchoolLife/SchoolLife.jsx';
import WhoWeAre from './components/WhoWeAre/WhoWeAre.jsx';
import Academics from './components/Academics/Academics.jsx';
import Gallery from './components/Gallery/Gallery.jsx';
import FinnishEdge from './components/FinnishEdge/FinnishEdge.jsx';
import HomeComponent from './components/HomeComponent/HomeComponent.jsx';
import DesktopNav from './components/DesktopNav/DesktopNav.jsx';
import MobileNav from './components/MobileNav/MobileNav.jsx';
import TagManager from 'react-gtm-module';
import { useEffect } from 'react';

function App() {
  useEffect(() => {
    const tagManagerArgs = {
      gtmId: process.env.REACT_APP_GTM_ID
    };
    TagManager.initialize(tagManagerArgs);
  }, []); 

  return (

    <>
    {window.screen.width >= 990 && (    <DesktopNav/>)}
    {window.screen.width < 990 && (    <MobileNav/>)}

    
      
      <HomeComponent />
      <Academics />
      <About />
      <WhoWeAre />
      <SchoolLife />
      <FinnishEdge />
      <Gallery />
      <Footer />


    </>
  );
}

export default App;
